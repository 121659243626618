import { z } from "zod";

import { SchemaProperties } from "@lib/data/repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { SmartActionTemplateSchema } from "./smart-action-template";

const SubscriptionStatusEnum = z.enum([
  "canceled",
  "scheduled",
  "active",
  "complete",
  "paused",
  "processing",
]);
export type SubscriptionStatusEnumType = z.infer<typeof SubscriptionStatusEnum>;

export enum SubscriptionTypeEnum {
  MonthlyPlan = "monthly-plan",
  AnnualPlan = "annual-plan",
}

export const SubscriptionSchema = BaseSchema.extend({
  subscriptionId: z.string(),
  totalInvoiceCount: z.number().optional(),
  total: z.number(),
  totalCollectable: z.number().optional(),
  invoicePricing: z.object({
    amount: z.number().min(0),
    tax: z.number().min(0).optional(),
    total: z.number().min(0),
  }),
  currency: z.string(),
  title: z.string(),
  status: SubscriptionStatusEnum,
  invoiceCount: z.number(),
  startDate: z.date(),
  coachUserId: z.string(),
  clientId: z.string(),
  frequency: z.object({
    interval: z.string(),
    intervalCount: z.number().optional(),
    description: z.string(),
  }),
  smartActionTemplates: z.array(SmartActionTemplateSchema).optional(),
  resumeAt: z.date().optional(),
  packageInstanceId: z.string().optional(),
});

export const schemaProperties: SchemaProperties = {
  collectionName: "subscriptions",
  deleteMode: "none",
};

export const subscriptionFrequenciesMap = {
  weekly: {
    title: "Every week",
    description: "Weekly",
    data: {
      interval: "week",
      intervalCount: 1,
    },
  },
  biWeekly: {
    title: "Every two weeks",
    description: "Two week",
    data: {
      interval: "week",
      intervalCount: 2,
    },
  },
  monthly: {
    title: "Every month",
    description: "Monthly",
    data: {
      interval: "month",
      intervalCount: 1,
    },
  },
  biMonthly: {
    title: "Every two months",
    description: "Two month",
    data: {
      interval: "month",
      intervalCount: 2,
    },
  },
  quarterly: {
    title: "Every three months",
    description: "Three month",
    data: {
      interval: "month",
      intervalCount: 3,
    },
  },
  semiAnnual: {
    title: "Every six months",
    description: "Six month",
    data: {
      interval: "month",
      intervalCount: 6,
    },
  },
  yearly: {
    title: "Every year",
    description: "Yearly",
    data: {
      interval: "year",
      intervalCount: 1,
    },
  },
};

export type SubscriptionType = z.infer<typeof SubscriptionSchema>;
